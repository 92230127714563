import { Component, OnInit } from '@angular/core';
import { NavigationComponent } from '../navigation/navigation.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private navigation: NavigationComponent) { }

  ngOnInit(): void {
  }

  navigateTo(element: string, event: Event): void {
    this.navigation.navigateTo(element, event);
  }

  scrollTo(element: string): void {
    this.navigation.scrollTo(element);
  }

}
