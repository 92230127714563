import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faFacebookF, faInstagram, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { MessageService } from 'src/app/core/message.service';
import { ThemeStatusService } from 'src/app/core/theme-status.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit {
  faWhatsapp = faWhatsapp;
  faFacebook = faFacebookF;
  faLinkedin = faLinkedinIn;
  faInstagram = faInstagram;
  contactForm!: FormGroup;
  error = '';
  successMessage = '';

  isDarkTheme = false;

  constructor(
    private themeService: ThemeStatusService,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    // Form builder init
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      serviceType: ['', Validators.required],
      message: ['', Validators.required],
    });

    this.themeService.isDarkTheme$.subscribe(
      (status) => (this.isDarkTheme = status)
    );
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      const messageData = this.contactForm.value;
      this.messageService.sendMessage(messageData).subscribe((response: string) => {
        this.successMessage = response;
        this.contactForm.reset();
        setTimeout(() => {
          this.closeAlert();
        }, 3000);
      }, (error: any) => {
        this.error = 'No se ha enviado el mensaje, intente más tarde';
        console.log(error);
        setTimeout(() => {
          this.closeAlert();
        }, 3000);
      });
    }
  }

  closeAlert(): void {
    this.error = '';
    this.successMessage = '';
  }
}
