<header class="toolbar">

  <!-- Sidenav button oppener -->
  <a class="toolbar__sidenav-oppener" (click)="toggleMenu()">
    <img [src]="isDarkTheme ? 'assets/images/menu-light.svg':'assets/images/menu.svg'" alt="open menu">
  </a>

  <!-- Toolbar logo -->
  <a [routerLink]="['/home']" class="toolbar__logo">
    <img [src]="isDarkTheme ? 'assets/images/logo-light.svg': 'assets/images/logo.svg'" alt="PPI Consulting Group">
  </a>

  <!-- Toolbar navigation -->
  <nav class="toolbar__nav">
    <ul>
      <li><a (click)="scrollTo('services'); setActive($event)">{{'navServices' | translate}}</a></li>
      <li><a id="navAbout" (click)="navigateTo('aboutSection', $event); setActive($event)">{{'navAbout' | translate}}</a></li>
      <li><a (click)="scrollTo('ourProducts'); setActive($event)">{{'navProducts' | translate}}</a></li>
      <li><a (click)="goToBlog()">{{'navBlog' | translate}}</a></li>
      <li><a (click)="scrollTo('contact'); setActive($event)">{{'navContact' | translate}}</a></li>
    </ul>
  </nav>

  <!-- Lang theme wrapper -->
  <div class="toolbar__lang-theme-wrapper">
    <!-- Toolbar language picker -->
    <div class="toolbar__lang-menu">
      <a (click)="switchLang('es'); setActive($event)" class="active" >
        <img [src]="'assets/images/es-lang.svg'" alt="Español">
        Esp
      </a>
      /
      <a (click)="switchLang('en'); setActive($event)" >
        Eng
        <img [src]="'assets/images/en-lang.svg'" alt="Español">
      </a>
    </div>

    <!-- Theme toggler -->
    <a class="toolbar__theme-picker" (click)="changeTheme();"><img [src]="isDarkTheme ? 'assets/images/light-on.svg' : 'assets/images/light-off.svg'" alt="Change Theme"></a>
  </div>
</header>


<!-- Sidenav -->
<div class="sidenav-backdrop" (click)="toggleMenu()"></div>
<div class="sidenav">
  <div class="sidenav__content-wrapper">
    <!-- Sidenav header -->
    <div class="sidenav__header">
      <span (click)="toggleMenu()" [style]="!isDarkTheme ? 'color: var(--gray-color-1)' : ''">✕</span>
      <img [src]="isDarkTheme ? 'assets/images/logo-light.svg': 'assets/images/logo.svg'" alt="PPI Consulting Group" height="60">
    </div>

    <!-- Sidenav body -->
    <div class="sidenav__body">
      <nav class="sidenav__menu">
        <ul>

          <li><a (click)="scrollTo('services'); setActive($event); toggleMenu()">{{'navServices' | translate}}</a></li>
          <li><a id="navAbout" (click)="navigateTo('aboutSection', $event); setActive($event); toggleMenu()">{{'navAbout' | translate}}</a></li>
          <li><a (click)="scrollTo('ourProducts'); setActive($event); toggleMenu()">{{'navProducts' | translate}}</a></li>
          <li><a (click)="goToBlog()">{{'navBlog' | translate}}</a></li>
          <li><a (click)="scrollTo('contact'); setActive($event); toggleMenu()">{{'navContact' | translate}}</a></li>
        </ul>
      </nav>

      <div class="sidenav__footer-wrapper">
        <div class="sidenav__lang-menu">
          <a (click)="switchLang('es'); setActive($event)">
            <img [src]="'assets/images/es-lang.svg'" alt="Español"> ESP
          </a>
          <span>/</span>
          <a (click)="switchLang('en'); setActive($event)">
            ENG <img [src]="'assets/images/en-lang.svg'" alt="Inglés">
          </a>
        </div>

        <a class="sidenav__theme-picker" (click)="changeTheme();">
          <img [src]="isDarkTheme ? 'assets/images/light-on.svg' : 'assets/images/light-off.svg'" alt="Change Theme">
        </a>
      </div>

    </div>

  </div>
</div>

<a id="goUpBtn" class="go-up-btn" (click)="goToTop()" *ngIf="windowScrolled"><img [src]="'assets/images/up.svg'" alt="Go up"></a>
