import { Component, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ThemeStatusService } from '../core/theme-status.service';
import { NavigationComponent } from '../shared/navigation/navigation.component';

@Component({
  selector: 'app-design-ux-ui',
  templateUrl: './design-ux-ui.component.html',
  styleUrls: ['./design-ux-ui.component.css'],
})
export class DesignUxUiComponent implements OnInit {
  isDarkTheme = false;
  faCircle = faCircle;

  constructor(private themeService: ThemeStatusService , private navigation: NavigationComponent) {}

  ngOnInit(): void {
    this.themeService.isDarkTheme$.subscribe(status => this.isDarkTheme = status);
  }

  // Call scroll to function in navigation controller
  scrollTo(element: string): void {
    this.navigation.scrollTo(element);
  }
}
