import { Component, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ThemeStatusService } from '../core/theme-status.service';
import { NavigationComponent } from '../shared/navigation/navigation.component';

@Component({
  selector: 'app-hardware-development',
  templateUrl: './hardware-development.component.html',
  styleUrls: ['./hardware-development.component.css'],
})
export class HardwareDevelopmentComponent implements OnInit {
  isDarkTheme = false;
  faCircle = faCircle;

  constructor(
    private themeStatus: ThemeStatusService,
    private navigation: NavigationComponent
  ) {}

  ngOnInit(): void {
    this.themeStatus.isDarkTheme$.subscribe(
      (status) => (this.isDarkTheme = status)
    );
  }

  // Call scroll to function in navigation controller
  scrollTo(element: string): void {
    this.navigation.scrollTo(element);
  }
}
