<div class="main-hero">
  <div class="main-hero__info-cta">
    <h1 class="main-hero__title">{{'mainHeroTitleP1' | translate}} <span>{{'mainHeroTitleP2' | translate}}</span>,<br>
      {{'mainHeroTitleP3' | translate}} <span>{{'mainHeroTitleP4' | translate}}</span> {{'mainHeroTitleP5' | translate}}
      <span>{{'mainHeroTitleP6' | translate}}</span></h1>

    <h2 class="main-hero__subtitle">{{'mainHeroSubtitle' | translate}}</h2>
    <img [src]="'assets/images/un.svg'" class="main-hero__un-image" alt="UN" loading="lazy">
    <a (click)="scrollTo('contact')" class="main-hero__cta">{{'mainHeroCTA' | translate}}</a>
  </div>

  <a (click)="scrollTo('aboutSection')" class="hero__arrow-down">
    <img [src]="isDarkTheme ? 'assets/images/arrow-light.svg' : 'assets/images/arrow-dark.svg'" alt="Go down" loading="lazy">
  </a>

  <img [src]="isDarkTheme ? 'assets/images/main-hero-dark-shape-left.svg' : 'assets/images/main-hero-light-shape-left.svg'" alt="shape" class="main-hero__shape shape-left">
  <img [src]="isDarkTheme ? 'assets/images/main-hero-dark-shape-right.svg' : 'assets/images/main-hero-light-shape-right.svg'" alt="shape" class="main-hero__shape shape-right">
</div>

<section id="aboutSection" class="section about-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="section-title text-left">{{'aboutTitle' | translate}}</h3>
        <p class="about-section__about-description">{{'aboutText' | translate}}</p>

        <div id="services" class="about-section__our-services">
          <div class="aboutSection__descriptions-wrapper">
            <h3 class="section-title text-left">{{'ourServicesTitle' | translate}}</h3>

            <p>{{ 'serviceText1' | translate}}</p>
            <p>{{ 'serviceText2' | translate}}</p>
            <p>{{ 'serviceText3' | translate}}</p>
            <p>{{ 'serviceText4' | translate}}</p>
          </div>

          <div class="aboutSection__services-card-wrapper">
            <a class="about-service-card" [routerLink]="['/web']">
              <img [src]="'assets/images/html-tag.svg'" alt="Desarrollo web" loading="lazy">
              <p>{{'serviceCard1' | translate}}</p>
            </a>

            <a class="about-service-card" [routerLink]="['/design']">
              <img [src]="'assets/images/ux.svg'" alt="Diseño Ux/Ui" loading="lazy">
              <p>{{'serviceCard2' | translate}}</p>
            </a>

            <a class="about-service-card" [routerLink]="['/mobile']">
              <img [src]="'assets/images/phone.svg'" alt="Desarrollo móvil" loading="lazy">
              <p>{{'serviceCard3' | translate}}</p>
            </a>

            <a class="about-service-card" [routerLink]="['/hardware']">
              <img [src]="'assets/images/processor.svg'" alt="Desarrollo hardware" loading="lazy">
              <p>{{'serviceCard4' | translate}}</p>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

  <img [src]="isDarkTheme ? 'assets/images/about-dark-shape.svg' : 'assets/images/about-light-shape.svg'" alt="shape" class="about-section__shape">
</section>

<!-- Section our products -->
<app-our-products></app-our-products>

<!-- Section our partner -->
<section id="partners" class="section our-partners">
  <div class="container">

    <div class="partner__filters-wrapper">
      <a class="partner__filter active" (click)="showAllPartners(); setActive($event)">{{'allPartnersBtn' | translate}}</a>
      <a class="partner__filter" (click)="showPartnersByCategory(2); setActive($event)">{{'ourPartnersBtn' | translate}}</a>
      <a class="partner__filter" (click)="showPartnersByCategory(1); setActive($event)">{{'ourClientsBtn' | translate}}</a>
      <a class="partner__filter" (click)="showPartnersByCategory(3); setActive($event)">{{'ourCollaboratorsBtn' | translate}}</a>
    </div>

    <div class="partner__logos-wrapper">
      <img *ngFor="let partner of filteredPartners" class="partner-image {{partner.name}}" src="{{ partner.image }}" alt="Partner" loading="lazy">
    </div>

  </div>
</section>

<!-- Section success cases -->
<section class="section sucess-cases">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="section-title">{{'successCases' | translate}}</h3>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="swiper-success-cases-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <picture>
                <source [srcset]="'assets/images/gallery/img-1.webp'" type="image/webp">
                <img [src]="'assets/images/gallery/img-1.png'" alt="Image" loading="lazy">
              </picture>
            </div>

            <div class="swiper-slide">
              <picture>
                <source [srcset]="'assets/images/gallery/img-2.webp'" type="image/webp">
                <img [src]="'assets/images/gallery/img-2.png'" alt="Image" loading="lazy">
              </picture>
            </div>

            <div class="swiper-slide">
              <picture>
                <source [srcset]="'assets/images/gallery/img-3.webp'" type="image/webp">
                <img [src]="'assets/images/gallery/img-3.png'" alt="Image" loading="lazy">
              </picture>
            </div>

            <div class="swiper-slide">
              <picture>
                <source [srcset]="'assets/images/gallery/img-4.webp'" type="image/webp">
                <img [src]="'assets/images/gallery/img-4.png'" alt="Image" loading="lazy">
              </picture>
            </div>

            <div class="swiper-slide">
              <picture>
                <source [srcset]="'assets/images/gallery/img-5.webp'" type="image/webp">
                <img [src]="'assets/images/gallery/img-5.png'" alt="Image" loading="lazy">
              </picture>
            </div>

            <div class="swiper-slide">
              <picture>
                <source [srcset]="'assets/images/gallery/img-6.webp'" type="image/webp">
                <img [src]="'assets/images/gallery/img-6.png'" alt="Image" loading="lazy">
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


