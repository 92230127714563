<section id="contact" class="section contact-section">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6 col-lg-5 order-md-last left-half">
        <div class="contact__form-wrapper">
          <h2 class="section-title text-left">{{ 'contactTitle' | translate}}</h2>
          <a href="https://api.whatsapp.com/send?phone=528123439971&text=Me%20gustaría%20obtener%20más%20información%20sobre%20sus%20productos%20y%20servicios"
                target="_blank" class="contact__wp-button mt-3 mt-md-4" style="text-decoration: none;"
                rel="noopener noreferrer"><fa-icon [icon]="faWhatsapp"></fa-icon> +52 81 2343 9971</a>

          <form id="mail-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="col-12 p-0">
              <div class="form-group">
                <label for="email">{{'contactEmailLabel' | translate}}</label>
                <input type="email" id="email" name="email" formControlName="email" placeholder="{{'contactEmailPlaceholder' | translate}}" required aria-required="true" aria-invalid="false">

                <div *ngIf="(f.email.touched || f.email.dirty) && f.email.errors?.required" class="d-block px-2">
                  <p class="input-error-message">{{'emailRequiredErrorLabel' | translate}}</p>
                </div>
                <div *ngIf="(f.email.touched || f.email.dirty) && f.email.errors?.email" class="d-block px-2">
                  <p class="input-error-message">{{'emailInvalidErrorLabel' | translate}}</p>
                </div>
              </div>
            </div>

            <div class="col-12 p-0">
              <div class="form-group">
                <label for="serviceType">{{'contactServiceLabel' | translate}}</label>
                <input type="text" id="serviceType" name="serviceType" formControlName="serviceType" placeholder="{{'contactServicePlaceholder' | translate}}" required aria-required="true" aria-invalid="false">

                <div *ngIf="(f.serviceType.touched || f.serviceType.dirty) && f.serviceType.errors?.required" class="d-block px-2">
                  <p class="input-error-message">{{'serviceRequiredErrorLabel' | translate}}</p>
                </div>
              </div>
            </div>

            <div class="col-12 p-0">
              <div class="form-group">
                <label for="message">{{'contactYourProjectLabel' | translate}}</label>
                <textarea id="message" name="message" formControlName="message" cols="40" rows="5" required aria-invalid="false"
                  placeholder="{{'contactYourProjectPlaceholder' | translate}}"></textarea>

                <div *ngIf="(f.message.touched || f.message.dirty) && f.message.errors?.required" class="d-block px-2">
                  <p class="input-error-message">{{'messageRequiredErrorLabel' | translate}}</p>
                </div>
              </div>
            </div>

            <div class="col-12 p-0">
              <div *ngIf="successMessage" class="email-alert alert-success text-center">
                <p class="m-0">{{successMessage}}</p>
                <span class="close-alert" (click)="closeAlert();">✕</span>
              </div>

              <div *ngIf="error" class="email-alert alert-danger text-center">
                <p class="m-0 px-2">{{error}}</p>
                <span class="close-alert" (click)="closeAlert();">✕</span>
              </div>
            </div>

            <div class="col-12 p-0">
              <div class="contact__social-links">
                  <span>{{'contactFollowText' | translate}}</span>
                  <a href="https://www.facebook.com/PPISmartTech/" rel="noopener noreferrer" target="_blank"><fa-icon [icon]="faFacebook"></fa-icon></a>
                  <a href="https://mx.linkedin.com/company/ppi-smart-tech" rel="noopener noreferrer" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
                  <a href="https://www.instagram.com/ppismarttech/?igshid=3omq90ceyuuf&hl=es-la" rel="noopener noreferrer" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
              </div>

              <button id="sendMessage" name="sendMessage" type="submit" [disabled]="contactForm.invalid">{{'contactSendBtntext' | translate}}</button>
            </div>

          </form>
        </div>
      </div>
      <div class="col-md-6 col-lg-5 order-md-first right-half">
        <img class="contact-illustration" [src]="isDarkTheme ? 'assets/images/contact-illustration-dark.svg' : 'assets/images/contact-illustration-light.svg'" alt="Contact">
      </div>
    </div>
  </div>

  <img [src]="isDarkTheme ? 'assets/images/contact-dark-shape.svg' : 'assets/images/contact-light-shape.svg'" alt="shape" class="contact-section__shape">
</section>
