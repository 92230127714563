import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DesignUxUiComponent } from './design-ux-ui/design-ux-ui.component';
import { MobileDevelopmentComponent } from './mobile-development/mobile-development.component';
import { HardwareDevelopmentComponent } from './hardware-development/hardware-development.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { PrivacyPoliciesComponent } from './privacy-policies/privacy-policies.component';
import { LazyImageDirective } from './core/directives/lazy-image.directive';
import { GoogleAnalyticsService } from './core/google-analytics.service';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DesignUxUiComponent,
    MobileDevelopmentComponent,
    HardwareDevelopmentComponent,
    WebDevelopmentComponent,
    PrivacyPoliciesComponent,
    LazyImageDirective,
    TermsAndConditionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxJsonLdModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

