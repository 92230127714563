<section id="services" class="section service-cards">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="section-title text-left">{{'ourServicesTitle' | translate}}</h3>
      </div>
    </div>
    <div class="row align-items-center justify-content-center justify-content-lg-between mt-3">
      <div class="col-6 col-md-3">
        <a [routerLink]="['/web']" class="service-card">
          <img [src]="'assets/images/html-tag.svg'" alt="Desarrollo web">
          <span>{{'serviceCard1' | translate}}</span>
        </a>
      </div>

      <div class="col-6 col-md-3">
        <a [routerLink]="['/design']" class="service-card">
          <img [src]="'assets/images/ux.svg'" alt="Diseño ux">
          <span>{{'serviceCard2' | translate}}</span>
        </a>
      </div>

      <div class="col-6 col-md-3">
        <a [routerLink]="['/mobile']"  class="service-card">
          <img [src]="'assets/images/phone.svg'" alt="Desarrollo móvil">
          <span>{{'serviceCard3' | translate}}</span>
        </a>
      </div>

      <div class="col-6 col-md-3">
        <a [routerLink]="['/hardware']" routerLinkActive="router-link-active"  class="service-card">
          <img [src]="'assets/images/processor.svg'" alt="Desarrollo de hardware">
          <span>{{'serviceCard4' | translate}}</span>
        </a>
      </div>
    </div>
  </div>

  <img [src]="isDarkTheme ? 'assets/images/circle-dark.svg' : 'assets/images/circle-light.svg'" alt="shape" class="service-cards__left-shape">
  <img [src]="isDarkTheme ? 'assets/images/skills-dark-shape.svg' : 'assets/images/skills-light-shape.svg'" alt="shape" class="service-cards__right-shape">
</section>
