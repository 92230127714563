import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { OurProductsComponent } from './our-products/our-products.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OurSkillsComponent } from './our-skills/our-skills.component';
import { ServicesCardsComponent } from './services-cards/services-cards.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    NavigationComponent,
    FooterComponent,
    OurProductsComponent,
    ContactFormComponent,
    OurSkillsComponent,
    ServicesCardsComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  exports: [
    NavigationComponent,
    FooterComponent,
    OurProductsComponent,
    ContactFormComponent,
    OurSkillsComponent,
    ServicesCardsComponent,
    FontAwesomeModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
