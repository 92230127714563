import { ViewportScroller } from '@angular/common';
import { Component, HostListener, Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'src/app/core/google-analytics.service';
import { ThemeStatusService } from 'src/app/core/theme-status.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class NavigationComponent implements OnInit {
  isDarkTheme = false;
  windowScrolled = false;

  // Set var true when window scroll down to show go to top button
  @HostListener('window:scroll') onScroll(): void {
    if (window.pageYOffset > 300 || document.documentElement.scrollTop > 300 || document.body.scrollTop > 300) {
      this.windowScrolled = true;
    }

    else if (this.windowScrolled && window.pageYOffset < 300 || document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      this.windowScrolled = false;
    }
  }

  constructor(
    private themeService: ThemeStatusService,
    public translate: TranslateService,
    private router: Router,
    private viewPortScroller: ViewportScroller,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    this.themeService.isDarkTheme$.subscribe(
      (status) => (this.isDarkTheme = status)
    );
  }

  // Add dark-theme class to body for theme change
  changeTheme(): void {
    this.isDarkTheme = !this.isDarkTheme;
    this.themeService.changeTheme(this.isDarkTheme);
    document.body.classList.toggle('dark-theme');
  }

  // Open - close sidenav menu
  toggleMenu(): void {
    const sidenav = document.getElementsByClassName('sidenav')[0];
    const backdrop = document.getElementsByClassName('sidenav-backdrop')[0];
    sidenav.classList.toggle('is-open');
    backdrop.classList.toggle('show');
  }

  // Go to page
  navigateTo(element: string, event: Event): void {
    const clickedElement: string = (event.target as Element).id;
    if (clickedElement === 'navAbout' && this.router.url.includes('home')) {
      this.scrollTo(element);
    } else {
      this.router.navigateByUrl('/home').then(() => {
        this.scrollTo(element);
      });
    }
  }

  // Scroll to an html element passing string id
  scrollTo(element: string): void {
    const htmlEl = document.querySelector(`#${element}`) as HTMLElement;

    htmlEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }

  // Set scroll position on top
  goToTop(): void {
    this.viewPortScroller.scrollToPosition([0, 0]);
  }


  // Used for switch language
  switchLang(lang: string): void {
    this.translate.use(lang);
  }

  // Set active class to selected client filter
  setActive(event: Event): void {
    const selectedEl = (event.target as HTMLElement);
    const elSiblings = selectedEl?.parentNode?.parentNode?.querySelectorAll('a');

    elSiblings?.forEach((item) => {
      item.classList.remove('active');
    });

    selectedEl.classList.add('active');
  }

  // Go to blog
  goToBlog(): void {
    this.googleAnalyticsService.eventEmitter('Ir al blog', 'navigation', 'click');
    window.open('https://blog.webppi.com/', '_blnak', 'noopener, noreferrer');
  }
}
