import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DesignUxUiComponent } from './design-ux-ui/design-ux-ui.component';
import { HardwareDevelopmentComponent } from './hardware-development/hardware-development.component';
import { HomeComponent } from './home/home.component';
import { MobileDevelopmentComponent } from './mobile-development/mobile-development.component';
import { PrivacyPoliciesComponent } from './privacy-policies/privacy-policies.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions/terms-and-conditions.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'design',
    component: DesignUxUiComponent
  },
  {
    path: 'mobile',
    component: MobileDevelopmentComponent
  },
  {
    path: 'hardware',
    component: HardwareDevelopmentComponent
  },
  {
    path: 'web',
    component: WebDevelopmentComponent
  },
  {
    path: 'privacy-policies',
    component: PrivacyPoliciesComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
