import { Injectable } from '@angular/core';

declare let gtag: (arg1: string, arg2: string, arg3: {}) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(eventName: string, eventCategory: string, eventAction: string): void {
    gtag('event', eventName, {
      eventCategory,
      eventAction
    });
  }
}
