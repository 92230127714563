import { Component, OnInit } from '@angular/core';
import { ThemeStatusService } from 'src/app/core/theme-status.service';

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.css'],
})
export class OurProductsComponent implements OnInit {
  isDarkTheme = false;

  constructor(private themeService: ThemeStatusService) {}

  ngOnInit(): void {
    this.themeService.isDarkTheme$.subscribe(
      (status) => (this.isDarkTheme = status)
    );
  }
}
