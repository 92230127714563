<footer>
  <div class="footer-wrapper">
    <!-- Logo -->
     <img [src]="'assets/images/logo-light.svg'" alt="PPI Smart Tech" height="60" class="footer__logo">

    <!-- Navigation -->
    <div class="footer__nav">
      <ul>
        <li><a (click)="scrollTo('services')">{{'navServices' | translate}}</a></li>
        <li><a id="navAbout" (click)="navigateTo('aboutSection', $event)">{{'navAbout' | translate}}</a></li>
        <li><a (click)="scrollTo('ourProducts')">{{'navProducts' | translate}}</a></li>
        <!-- <li><a href="#">{{'navBlog' | translate}}</a></li> -->
        <li><a (click)="scrollTo('contact')">{{'navContact' | translate}}</a></li>
      </ul>
    </div>

    <!-- Contact info -->
    <div class="footer_contact-info">
      <h3>{{'footerInfoTitle' | translate}}</h3>

      <p>{{'footerAdressP1' | translate}}, <br>
      {{'footerAdressP2' | translate}} <br>
      {{'footerAdressP3' | translate}}</p>

      <a href="#" class="wp-button">
        <img [src]="'assets/images/wp-logo.svg'" alt="WhatsApp"> WhatsApp: +52 81 2343 9971</a>

      <p>{{'footerEmail' | translate}}: info@webppi.com</p>
    </div>

    <!-- Terms & conditions -->
    <div class="footer__terms-conditions">
      <a [routerLink]="['/privacy-policies']" routerLinkActive="router-link-active" >{{'footerPrivacy' | translate}}</a>
      <a  [routerLink]="['/terms-and-conditions']" routerLinkActive="router-link-active" >{{'footerTerms' | translate}}</a>
    </div>

    <!-- Copiright legend -->
    <p class="footer__copyright">© Copyright 2021 PPI Smart Tech | {{'footerRights' | translate}}</p>
  </div>
</footer>
