<section id="ourSkills" class="section our-skills">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="section-title text-left">{{'skillsTitle' | translate}}</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="our-skills__wrapper">

          <div class="skills-card">
            <p>{{'skill-1' | translate}}</p>
          </div>

          <div class="skills-card">
            <p>{{'skill-2' | translate}}</p>
          </div>

          <div class="skills-card">
            <p>{{'skill-3' | translate}}</p>
          </div>

          <div class="skills-card">
            <p>{{'skill-4' | translate}}</p>
          </div>

        </div>
      </div>
    </div>
  </div>

  <img [src]="isDarkTheme ? 'assets/images/skills-dark-shape.svg' : 'assets/images/skills-light-shape.svg'" alt="shape" class="our-skills__left-shape">
  <img [src]="isDarkTheme ? 'assets/images/skills-dark-shape.svg' : 'assets/images/skills-light-shape.svg'" alt="shape" class="our-skills__right-shape">
</section>
