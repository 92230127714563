<section id="ourProducts" class="section our-products">
  <div class="container">
    <h3 class="section-title">{{'ourProductsTitle' | translate}}</h3>

    <div class="our-products__products-wrapper mt-3">
      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/ft-light.svg' : 'assets/images/ft-color.svg'" alt="logo" loading="lazy">
      </div>

      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/fc-light.svg' : 'assets/images/fc-color.svg'" alt="logo" loading="lazy">
      </div>

      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/fn-light.svg' : 'assets/images/fn-color.svg'" alt="logo" loading="lazy">
      </div>

      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/fd-light.svg' : 'assets/images/fd-color.svg'" alt="logo" loading="lazy">
      </div>

      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/fs-light.svg' : 'assets/images/fs-color.svg'" alt="logo" loading="lazy">
      </div>

      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/lighten-light.svg' : 'assets/images/lighten-color.svg'" alt="logo" loading="lazy">
      </div>

      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/conekcity-light.svg' : 'assets/images/conekcity-color.svg'" alt="logo" loading="lazy">
      </div>

      <!-- product -->
      <div class="product-card">
        <img [src]="isDarkTheme ? 'assets/images/conthea-light.svg' : 'assets/images/conthea-color.svg'" alt="logo" loading="lazy">
      </div>

    </div>
  </div>
</section>
