import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }

  sendMessage(body: any): any {
    return this.http.post(
      'https://mailer.webppi.com/mailsender.php',
      JSON.stringify(body),
      { responseType: 'text' }
    );
  }
}
