<section id="privacyPolicies" class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h3 class="section-title text-left">{{'privacyTitle' | translate}}</h3>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <p>{{ 'privacyText1' | translate }}</p>

        <ul>
          <li>{{ 'privacyList1-Item1' | translate }}</li>
          <li>{{ 'privacyList1-Item2' | translate }}</li>
          <li>{{ 'privacyList1-Item3' | translate }}</li>
          <li>{{ 'privacyList1-Item4' | translate }}</li>
          <li>{{ 'privacyList1-Item5' | translate }}</li>
          <li>{{ 'privacyList1-Item6' | translate }}</li>
        </ul>

        <p>{{ 'privacyText2' | translate }}</p>

        <ul>
          <li>{{ 'privacyList2-Item1' | translate }}</li>
          <li>{{ 'privacyList2-Item2' | translate }}</li>
          <li>{{ 'privacyList2-Item3' | translate }}</li>
          <li>{{ 'privacyList2-Item4' | translate }}</li>
          <li>{{ 'privacyList2-Item5' | translate }}</li>
        </ul>

        <p>{{ 'privacyText3' | translate }}</p>

        <p>{{ 'privacyText4' | translate }}</p>

        <p>{{ 'privacyText5' | translate }}</p>

        <p>{{ 'privacyText6' | translate }}</p>

        <p>{{ 'privacyText7' | translate }}</p>

        <p>{{ 'privacyText8' | translate }}</p>

        <p>{{ 'privacyText9' | translate }}</p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-10 privacy-footer">
        <p>{{ 'privacyText10' | translate }}</p>
        <p>{{ 'privacyText11' | translate }}</p>
      </div>
    </div>

  </div>
</section>
