import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: (arg1: string, arg2: string, arg3: {}) => void;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'webppi';

  schemaBusinnes = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'PPI Smart Tech',
    description: 'Líderes en Innovación, desarrollo de Software y Hardware',
    url: 'https://www.webppi.com/',
    logo: 'https://www.webppi.com/assets/img/pingpong.png',
    image: 'https://www.webppi.com/assets/img/ppw.jpg',
    telephone: '+52 (0181) 23.43.99.71',
    email: 'info@pwebppi.com',
    priceRange: '$0-$100,000',
    openingHours: 'Mo-Fr 08:30-18:00',
    contactPoint: [{
      '@type': 'ContactPoint',
      telephone: '+52 (0181) 23.43.99.71',
      contactType: 'customer service',
      areaServed: 'MX, US'
    }],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Calle José María de Teresa 188 Campestre',
      addressLocality: 'CDMX',
      addressRegion: 'Ciudad de México',
      postalCode: '01040'
    }
  };

  schemaOrganization = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: 'https://www.webppi.com/',
    logo: 'https://www.webppi.com/assets/img/pingpong.png',
    contactPoint: [{
      '@type': 'ContactPoint',
      telephone: '+52 (0181) 23.43.99.71',
      contactType: 'customer service',
      areaServed: 'MX, US',
      availableLanguage: {
        '@type': 'Language',
        name: ['Spanish', 'English']
      }
    }]
  };

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-161413498-3', {page_path: event.urlAfterRedirects});
      }
    });
  }
}
