<div class="hero">

  <div class="hero__wrapper">

    <!-- Info - CTA -->
    <div class="hero__info-cta">
      <h1 class="hero__title">{{'mobile-heroTitleP1' | translate}} <span>{{'mobile-heroTitleP2' | translate}}</span></h1>
      <div class="hero__navigation">
        <a (click)="scrollTo('ourApproach')"><fa-icon [icon]="faCircle"></fa-icon>{{'mobile-heroNav1' | translate}}</a>
        <a (click)="scrollTo('metodology')"><fa-icon [icon]="faCircle"></fa-icon>{{'mobile-heroNav2' | translate}}</a>
        <a (click)="scrollTo('tools')"><fa-icon [icon]="faCircle"></fa-icon>{{'mobile-heroNav3' | translate}}</a>
      </div>
      <a (click)="scrollTo('contact')" class="hero__cta">{{'mobile-heroCTA' | translate}}</a>
    </div>

    <!-- Illustration -->
    <img class="hero__image" [src]="'assets/images/mobile-hero-img.svg'" alt="Image">
  </div>

  <a (click)="scrollTo('ourApproach')" class="hero__arrow-down">
    <img [src]="isDarkTheme ? 'assets/images/arrow-light.svg' : 'assets/images/arrow-dark.svg'" alt="Go down">
  </a>
</div>

<!-- Our aproach section -->
<section id="ourApproach" class="section our-approach">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="section-title text-left">{{'ourApproachTitle' | translate}}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <p class="our-approach__text">{{'mobile-ourApproachText1' | translate}}</p>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <p class="our-approach__text">{{'mobile-ourApproachText2' | translate}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <p class="our-approach__text bordered bordered-1">{{'mobile-ourApproachText3' | translate}}</p>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <p class="our-approach__text bordered bordered-2">{{'mobile-ourApproachText4' | translate}}</p>
      </div>
    </div>
  </div>
</section>

<!-- Metodology section -->
<section id="metodology" class="section metodology">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="section-title text-left">{{'metodologyTitle' | translate}}</h3>
      </div>
    </div>

    <div class="row align-items-center justify-content-center justify-content-lg-between mt-3">

      <!-- metodology card -->
      <div class="col-6 col-md-4 col-lg-2">
        <div class="metodology__card">
          <p><fa-icon [icon]="faCircle"></fa-icon> {{'mobile-metodologyTextItem1' | translate}}</p>
        </div>
      </div>

      <!-- metodology card -->
      <div class="col-6 col-md-4 col-lg-2">
        <div class="metodology__card">
          <p><fa-icon [icon]="faCircle"></fa-icon> {{'mobile-metodologyTextItem2' | translate}}</p>
        </div>
      </div>

      <!-- metodology card -->
      <div class="col-6 col-md-4 col-lg-2">
        <div class="metodology__card">
          <p><fa-icon [icon]="faCircle"></fa-icon> {{'mobile-metodologyTextItem3' | translate}}</p>
        </div>
      </div>

      <!-- metodology card -->
      <div class="col-6 col-md-4 col-lg-2">
        <div class="metodology__card">
          <p><fa-icon [icon]="faCircle"></fa-icon> {{'mobile-metodologyTextItem4' | translate}}</p>
        </div>
      </div>

      <!-- metodology card -->
      <div class="col-6 col-md-4 col-lg-2">
        <div class="metodology__card">
          <p><fa-icon [icon]="faCircle"></fa-icon> {{'mobile-metodologyTextItem5' | translate}}</p>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- Tools section -->
<section id="tools" class="section tools">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="section-title text-left">{{'toolsTitle' | translate}}</h3>
      </div>
    </div>
    <div class="row align-items-center mt-3">
      <div class="col">
        <img [src]="'assets/images/logos/swift-logo.svg'" alt="Swift">
      </div>

      <div class="col">
        <img [src]="'assets/images/logos/atom-logo.svg'" alt="atom">
      </div>

      <div class="col">
        <img [src]="'assets/images/logos/flutter-logo.svg'" alt="fluter">
      </div>

      <div class="col">
        <img [src]="'assets/images/logos/kotlin-logo.svg'" alt="kotlin">
      </div>

    </div>
  </div>
</section>

<!-- Best option section -->
<app-our-skills></app-our-skills>

<!-- Our products section -->
<app-our-products></app-our-products>

<!-- Our services section -->
<app-services-cards></app-services-cards>
