<section id="termsAndConditions" class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h3 class="section-title text-left">{{'termsTitle' | translate}}</h3>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <p>{{ 'termsText1' | translate }}</p>
        <p>{{ 'termsText2' | translate }}</p>

        <p class="mt-5">{{ 'termsSubtitle1' | translate }}</p>
        <p>{{ 'termsText3' | translate }}</p>
        <p>{{ 'termsText4' | translate }}</p>
        <p>{{ 'termsText5' | translate }}</p>
        <p>{{ 'termsText6' | translate }}</p>
        <p>{{ 'termsText7' | translate }}</p>
        <p>{{ 'termsText8' | translate }}</p>
        <p>{{ 'termsText9' | translate }}</p>

        <p class="mt-5">{{ 'termsSubtitle2' | translate }}</p>
        <p>{{ 'termsText10' | translate }}</p>
        <p>{{ 'termsText11' | translate }}</p>
        <p>{{ 'termsText12' | translate }}</p>
        <p>{{ 'termsText13' | translate }}</p>
        <p>{{ 'termsText14' | translate }}</p>

        <p class="mt-5">{{ 'termsSubtitle3' | translate }}</p>
        <p>{{ 'termsText15' | translate }}</p>
        <p>{{ 'termsText16' | translate }}</p>
        <p>{{ 'termsText17' | translate }}</p>

        <p class="mt-5">{{ 'termsSubtitle4' | translate }}</p>
        <p>{{ 'termsText18' | translate }}</p>
        <p>{{ 'termsText19' | translate }}</p>
        <p>{{ 'termsText20' | translate }}</p>
        <p>{{ 'termsText21' | translate }}</p>

        <a href="https://www.webppi.com/privacy-policies">https://www.webppi.com/privacy-policies</a>

        <p>{{ 'termsText22' | translate }}</p>
        <p>{{ 'termsText23' | translate }}</p>
        <p>{{ 'termsText24' | translate }}</p>
        <p>{{ 'termsText25' | translate }}</p>
        <p>{{ 'termsText26' | translate }}</p>
        <p>{{ 'termsText27' | translate }}</p>

        <p class="mt-5">{{ 'termsSubtitle5' | translate }}</p>
        <p>{{ 'termsText28' | translate }}</p>
        <p>{{ 'termsText29' | translate }}</p>

        <p class="mt-5">{{ 'termsSubtitle6' | translate }}</p>
        <p>{{ 'termsText30' | translate }}</p>
        <p>{{ 'termsText31' | translate }}</p>
        <p>{{ 'termsText32' | translate }}</p>
        <p>{{ 'termsText33' | translate }}</p>
        <p>{{ 'termsText34' | translate }}</p>

        <p class="mt-5">{{ 'termsSubtitle7' | translate }}</p>
        <p>{{ 'termsText35' | translate }}</p>
        <p>{{ 'termsText36' | translate }}</p>



      </div>
    </div>

  </div>
</section>
