import { AfterViewInit, Component, OnInit } from '@angular/core';
import Swiper from 'swiper';
import { Partner } from '../core/models/partners.model';
import { ThemeStatusService } from '../core/theme-status.service';
import { NavigationComponent } from '../shared/navigation/navigation.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  successCasesSlider: Swiper | undefined;
  isDarkTheme = false;
  partners: Partner[] = [
    { category: 1, image: 'assets/images/logos/logo-falcon.svg' },
    { category: 1, image: 'assets/images/logos/logo-harvard.svg'},
    { category: 1, image: 'assets/images/logos/logo-mit.svg' },
    { category: 1, image: 'assets/images/logos/logo-tec.svg' },
    { category: 1, image: 'assets/images/logos/logo-inc.svg' },
    { category: 2, image: 'assets/images/logos/logo-gh.svg' },
    { category: 1, image: 'assets/images/logos/logo-loop.svg' },
    { category: 1, image: 'assets/images/logos/logo-prepatec.svg' },
    { category: 2, image: 'assets/images/logos/logo-jmromo.svg' },
    { category: 3, image: 'assets/images/logos/logo-cpm.svg' },
    { category: 2, image: 'assets/images/logos/logo-grizzly.svg'},
    { category: 2, image: 'assets/images/logos/logo-azure.svg' },
    { category: 3, image: 'assets/images/logos/logo-com.svg' },
    { category: 3, image: 'assets/images/logos/logo-chainway.svg' },
    { category: 2, image: 'assets/images/logos/logo-impinj.svg' },
    { category: 3, image: 'assets/images/logos/logo-stripe.svg' },
    { category: 1, image: 'assets/images/logos/logo-sectur.svg' },
    { category: 1, image: 'assets/images/logos/logo-nl.svg' },
    { category: 1, image: 'assets/images/logos/logo-sanmiguel.svg'},
    { category: 1, image: 'assets/images/logos/logo-sanmarcos.svg' },
    { category: 1, image: 'assets/images/logos/logo-sre.svg' },
    { category: 3, image: 'assets/images/logos/logo-avery.svg' },
    { category: 1, image: 'assets/images/logos/logo-bbva.svg' },
  ];
  filteredPartners!: Partner[];

  constructor(
    private themeService: ThemeStatusService,
    private navigation: NavigationComponent
  ) {
    this.showAllPartners();
  }

  ngOnInit(): void {
    this.themeService.isDarkTheme$.subscribe(
      (status) => (this.isDarkTheme = status)
    );
  }

  ngAfterViewInit(): void {
    this.successCasesSlider = new Swiper('.swiper-success-cases-container', {
      observer: true,
      observeParents: true,
      spaceBetween: 30,
      breakpoints: {
        640: {
          slidesPerView: 1,
          slidesPerColumn: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          slidesPerColumn: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          slidesPerColumn: 2,
          spaceBetween: 20,
        },
      },
    });
  }

  scrollTo(element: string): void {
    this.navigation.scrollTo(element);
  }

  // Show all partners
  showAllPartners(): void {
    this.filteredPartners = this.partners;
  }

  // Filter partners
  showPartnersByCategory(category: number): void {
    const filtered = this.partners.filter(
      (items) => category === items.category
    );
    this.filteredPartners = filtered;
  }

  // Set active class to selected client filter
  setActive(event: Event): void {
    document.querySelectorAll('.partner__filter').forEach((item) => {
      item.classList.remove('active');
    });

    (event.target as HTMLElement).classList.toggle('active');
  }
}
