import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeStatusService {

  private themeDark: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isDarkTheme$ = this.themeDark.asObservable();

  constructor() { }

  // Change theme status for using in other components
  changeTheme = (status: boolean) => this.themeDark.next(status);
}
