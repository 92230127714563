import { Component, OnInit } from '@angular/core';
import { ThemeStatusService } from 'src/app/core/theme-status.service';

@Component({
  selector: 'app-services-cards',
  templateUrl: './services-cards.component.html',
  styleUrls: ['./services-cards.component.css']
})
export class ServicesCardsComponent implements OnInit {

  isDarkTheme = false;

  constructor(private themeStatus: ThemeStatusService) { }

  ngOnInit(): void {
    this.themeStatus.isDarkTheme$.subscribe(status => this.isDarkTheme = status);
  }

}
